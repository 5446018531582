const phone = <svg xmlns="http://www.w3.org/2000/svg" className = "h-8 w-8" viewBox = "0 0 20 20" fill = "currentColor" >
    <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
</svg>

const letter = <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
    <path strokeLinecap="round" strokeLinejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
</svg>

const location = <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
    <path strokeLinecap="round" strokeLinejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
    <path strokeLinecap="round" strokeLinejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
</svg>

const contacts = [
    {
        icon: phone,
        title: '(+380) 50 130 45 18',
        href: 'tel:380501304518',
    },
    {
        icon: letter,
        title: 'jiskelo@gmail.com',
        href: 'mailto:jiskelo@gmail.com',
    },
    {
        icon: location,
        title: 'Ukraine, Kharkiv',
        href: 'https://goo.gl/maps/LcY7oE2SpdXF4xcHA',
    },
]

export default contacts